import { Container } from 'components/atomic-atoms/Container';
import { LabelType } from 'lib/types/label';
import { Labels } from 'components/atomic-molecules/labels';
import { NextButton } from 'components/atomic-atoms/Button';
import React from 'react';
import { UserSessionType } from 'lib/store';
import theme from 'components/atomic-atoms/theme';

type HeroSectionTypes = {
  title: string;
  text?: string | string[];
  children?: React.ReactNode;
  labels?: LabelType[];
  userProductWithContract?: boolean;
  offeringID?: string;
  user?: UserSessionType;
};

export const HeroSection = ({ title, text, children, labels, userProductWithContract, offeringID }: HeroSectionTypes) => {
  const ProductButton = () => {
    if (!offeringID) {
      return null;
    }

    if (userProductWithContract) {
      return (
        <Container
          color={theme.colors.white()}
          type="card"
          border={`2px solid ${theme.colors.darkOcean()}`}
          padding="10px"
          $backgroundColor={theme.colors.ocean()}
        >
          You can access the live version of this product as your organisation has signed an agreement
        </Container>
      );
    }

    return (
      <NextButton href={`/user/request-product-access/${offeringID}`} $secondary margin="0" width="fit-content" padding="5px">
        Request production access
      </NextButton>
    );
  };

  const ProductLabels = () => {
    if (!offeringID) {
      return null;
    }

    if (labels) {
      return (
        <Container type="content" $margin="0 0 30px">
          <Labels labels={labels} />
        </Container>
      );
    }

    return null;
  };

  return (
    <div className="relative z-[1] bg-sand-400 before:absolute before:z-[-1] before:h-full before:w-full before:bg-[url('/img/hero-bg.jpg')] before:bg-cover before:opacity-80">
      <div className="my-12">
        <div className="container mx-auto px-2 py-16 md:px-4">
          <ProductLabels />
          <h1 className="relative m-0 mb-5 pb-4 text-xl capitalize text-white after:absolute after:bottom-0 after:left-0 after:h-1 after:w-40 after:bg-ocean-500 xl:mb-8 xl:pb-10 xl:text-5xl">
            {title}
          </h1>
          <Container type="content" $flexDirection="row" $margin="0 0 20px">
            <ProductButton />
          </Container>
          <p className="mb-5 text-sm text-white xl:text-lg">{text}</p>
          {children}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
