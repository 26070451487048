import { ApiProduct, LegacyApiProduct } from 'lib/cms/queries';

import { Labels } from 'components/atomic-molecules/labels';
import Link from 'next/link';
import { fallback } from 'lib/cms/fallbackData';
import styled from 'styled-components';
import { NdsButton } from '@nds/react';
import { AiOutlineArrowRight, AiOutlineCodeSandbox, AiOutlineUser } from 'react-icons/ai';
import { BiBarChart, BiCreditCard, BiFilterAlt, BiSearch } from 'react-icons/bi';
import { TbReportSearch, TbUserSearch } from 'react-icons/tb';
import { MdGroups, MdOutlinePersonOutline } from 'react-icons/md';
import { RiUserSearchLine } from 'react-icons/ri';
import { FaCog, FaGlobeAmericas } from 'react-icons/fa';
import { HiDocumentReport } from 'react-icons/hi';
import { BsBuilding, BsGlobe } from 'react-icons/bs';
import { GiSpy } from 'react-icons/gi';

type ContentProps = {
  justifyContent?: string;
  height?: string;
  width?: string;
  marginTop?: string;
  type?: 'labels' | 'icon' | 'header' | 'text' | 'button' | 'desktopOnly';
  $icon?: boolean;
};

const Content = styled.div<ContentProps>`
  display: ${(props) => {
    if (props.type === 'desktopOnly') return 'none';
    return 'flex;';
  }};
  flex-direction: row;
  justify-content: ${(props) => {
    if (props.justifyContent) return props.justifyContent;
    if (props.type === 'button') return 'right';
    if (props.type === 'labels') return 'right';
  }};
  justify-self: ${(props) => {
    if (props.type === 'button') return 'right';
    return undefined;
  }}
  height: ${(props) => {
    if (props.height) return props.height;
    if (props.type === 'text') return 'fit-content';
    return undefined;
  }};
  width: ${(props) => {
    if (props.width) return props.width;
    if (props.type === 'button') return '100%';
    return undefined;
  }};
  margin-bottom: ${(props) => {
    if (props.type === 'text') return '10px';
    return undefined;
  }};
  overflow: ${(props) => {
    if (props.$icon) return 'hidden';
    return 'auto';
  }};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : undefined)};
  color: ${(props) => props.theme.colors.nightrider()};
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: ${(props) => {
      if (props.type === 'desktopOnly') return 'flex';
      return 'flex';
    }};
    justify-content: ${(props) => {
      if (props.justifyContent) return props.justifyContent;
      if (props.type === 'button') return 'center';
      if (props.type === 'labels') return 'right';
    }};
    height: ${(props) => {
      if (props.type === 'header') return '70px;';
      if (props.type === 'text') return '120px';
      return undefined;
    }};
  }
`;

export const EnentoCard = ({ apiProduct }: { apiProduct: ApiProduct | LegacyApiProduct }) => {
  const isLegacyProduct = !!(apiProduct.attributes.tabs ?? []).find((tab) => tab.type);
  let readMoreHref = `/api-product/${apiProduct.attributes.name.replaceAll(' ', '-').toLowerCase()}`;

  if (isLegacyProduct) {
    readMoreHref = `/api-product-legacy/${apiProduct.attributes.name.replaceAll(' ', '-').toLowerCase()}/${apiProduct.id}`;
  }

  const readMoreButtonText = (apiProduct.attributes.readMoreButtonText ?? fallback.readMoreButtonText).replaceAll('_', ' ');

  const renderIcon = (icon: string | undefined) => {
    switch (icon) {
      case 'BiSearch':
        return <BiSearch />;
      case 'AiOutlineUser':
        return <AiOutlineUser />;
      case 'TbReportSearch':
        return <TbReportSearch />;
      case 'MdOutlinePersonOutline':
        return <MdOutlinePersonOutline />;
      case 'RiUserSearchLine':
        return <RiUserSearchLine />;
      case 'BiFilterAlt':
        return <BiFilterAlt />;
      case 'TbUserSearch':
        return <TbUserSearch />;
      case 'FaCog':
        return <FaCog />;
      case 'HiDocumentReport':
        return <HiDocumentReport />;
      case 'MdGroups':
        return <MdGroups />;
      case 'BiCreditCard':
        return <BiCreditCard />;
      case 'BsGlobe':
        return <BsGlobe />;
      case 'FaGlobeAmericas':
        return <FaGlobeAmericas />;
      case 'BsBuilding':
        return <BsBuilding />;
      case 'GiSpy':
        return <GiSpy />;
      case 'AiOutlineCodeSandbox':
        return <AiOutlineCodeSandbox />;
      default:
        return <BiBarChart />;
    }
  };

  return (
    <div className="m-5 max-w-3xl rounded-md bg-white p-3 shadow-md xl:w-80">
      <Content type="labels">
        <Labels labels={apiProduct.attributes.labels} />
      </Content>
      <Content type="desktopOnly" $icon>
        <div className="mb-2 text-8xl text-ocean-500">{renderIcon(apiProduct.attributes.icon?.name)}</div>
      </Content>
      <Content type="header">
        <h4 className="m-0 font-bold">{apiProduct.attributes.name}</h4>
      </Content>
      <div className="mb-4 h-auto xl:h-24">
        <p role="paragraph" className="line-clamp-none xl:line-clamp-4">
          {apiProduct.attributes.summary}
        </p>
      </div>
      <div className="flex justify-end">
        <Link href={readMoreHref} passHref legacyBehavior>
          <NdsButton variant="text" fixedWidth={false}>
            <div className="flex items-center gap-2">
              {readMoreButtonText}
              <AiOutlineArrowRight className="text-xl"></AiOutlineArrowRight>
            </div>
          </NdsButton>
        </Link>
      </div>
    </div>
  );
};
