import { Label, LabelsContainer } from 'components/atomic-atoms/Label';

import { LabelType } from 'lib/types/label';

export const Labels = ({ labels }: { labels: LabelType[] }) => {
  return (
    <LabelsContainer role="group">
      {labels.map((label: LabelType, index: number) => (
        <Label text={label.text} key={`${label.text}-${index}`} type={label.type} />
      ))}
    </LabelsContainer>
  );
};
