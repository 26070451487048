import { ApiProduct } from 'lib/cms/queries';
import { EnentoCard } from 'components/atomic-molecules/enento-api-card';

export const APIProductCards = ({ apiProducts = [], startPage }: { apiProducts: ApiProduct[]; startPage?: boolean }) => {
  return (
    <div
      className={['container', 'flex', 'flex-col', 'xl:flex-row', 'mx-auto', 'items-center', 'xl:justify-center']
        .concat(startPage ? ['xl:flex-nowrap'] : ['xl:flex-wrap'])
        .join(' ')}
    >
      {apiProducts.map((api: ApiProduct) => (
        <EnentoCard key={`${api.attributes.name}`} apiProduct={api} />
      ))}
    </div>
  );
};
