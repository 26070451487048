type DefaultContent = {
  header: string;
  text: string;
};

type NavigationBar = {
  menuOptions: {
    buttonText: string;
    route: string;
    icon: string;
  }[];
  logo: {
    data: {
      attributes: {
        url: string | null;
        alternativeText: string;
      };
    };
  };
};

type feedbackSection = DefaultContent & {
  feedbackButtonText: string;
};

type Footer = {
  company: string;
  privacyPolicy: string;
};

type HeroSection = DefaultContent & {
  apiProductsButtonText: string;
};

type Localization = {
  locale: {
    code: string;
    language: string;
    show: boolean;
  }[];
};

type StartPageApiCollection = {
  products: string[];
};

export type FallbackValue = NavigationBar | DefaultContent | string | feedbackSection | Footer | HeroSection | Localization | StartPageApiCollection;

export const fallback = {
  navigationBar: {
    menuOptions: [
      {
        buttonText: 'Home',
        route: '/',
        icon: 'AiFillHome',
      },
      {
        buttonText: 'API Products',
        route: '/api-products',
        icon: 'AiFillApi',
      },
    ],
    logo: {
      data: {
        attributes: {
          url: null,
          alternativeText: 'developer_portal_logo.svg',
        },
      },
    },
  },
  readMoreButtonText: 'Read more',
  apiProductsPage: {
    header: 'API Products',
    text: `An API Product is a framework for accessing data in a way that fulfills a business need.
    It lets your digital product complete specific tasks while respecting customer privacy.
    Our API Products have been designed using business logic, 
    providing the exact insights needed to solve customer problems and streamline business processes.`,
  },
  feedBackSection: {
    header: 'Be involved in the development portal',
    text: `Our goal is to offer a self-service developer experience where you can discover, 
    explore and use UC APIs. 
    To create a great developer experience we need input and feedback from the users of this site. 
    What is working, what is not working, what do you miss and what can you live without? 
    Please let us know so that we together can make product development move faster.`,
    feedbackButtonText: 'Give feedback',
  },
  footer: {
    company: 'Enento Group',
    privacyPolicy: 'Privacy policy',
  },
  heroSection: {
    header: 'Let’s build trust and transparency',
    apiProductsButtonText: 'See all API products',
    text: `Welcome to UC’s Developer Portal. 
    Here you can discover and explore some of our API offerings. 
    At UC, we strive to empower our clients with intelligence. 
    Through this Portal our goal is to enable a great developer experience with useful products and comprehensive API documentation.`,
  },
  localization: {
    locale: [
      {
        code: 'en',
        language: 'english',
        show: true,
      },
      {
        code: 'sv-SE',
        language: 'svenska',
        show: true,
      },
    ],
  },
  startPageApiCollection: {
    products: [],
  },
};
