import { FeatureToggleServerType, IconTypes } from 'lib/types';

import { LabelType } from 'lib/types/label';
import { gql } from 'graphql-request';

export type ApiProductRef = {
  id: string;
  attributes: {
    apiProductID: string;
  };
};

export type ApiProductsRefsResponse = {
  apiProducts: {
    data: ApiProductRef[];
  };
};

export type ApiProductAttributes = {
  name: string;
  summary: string;
  description: string;
  spec: JSON;
  apiProductID: string;
};

export type ApiProductPageResponse = {
  apiProduct: {
    data: {
      attributes: ApiProductAttributes;
    };
  };
};

export type ApiProductsQueryResponse = {
  apiProducts: {
    data: {
      id: string;
      attributes: {
        localizations: {
          data: {
            attributes: {
              locale: string;
            };
          }[];
        };
      };
    }[];
  };
};

export type FeatureTogglesQueryResponse = {
  featureToggles: {
    data: FeatureToggleServerType[];
  };
};

export const featureTogglesQuery = gql`
  query featureTogglesQuery($filterByAppEnv: FeatureToggleFiltersInput!) {
    featureToggles(filters: $filterByAppEnv) {
      data {
        attributes {
          name
          devTest
          systemTest
          accTest
          production
        }
      }
    }
  }
`;

export type ApiProduct = {
  id: number;
  attributes: {
    name: string;
    summary: string;
    description: string;
    apiProductID: string;
    labels: LabelType[];
    icon?: IconTypes | undefined;
    spec: JSON;
    readMoreButtonText: string;
    tabs?: {
      type?: string;
    }[];
  };
};

export type StartPageApiProducts = {
  startPageApis: ApiProduct[];
};

export type APIProductCardsResponse = {
  startPageApis: ApiProduct[];
};

export type ApiProductCardsQueryResponse = {
  apiProducts: {
    data: ApiProduct[];
  };
};

export const apiProductCardsQuery = gql`
  query apiProductCardsQuery($locale: I18NLocaleCode, $filterByAppEnv: ApiProductFiltersInput!) {
    apiProducts(locale: $locale, filters: $filterByAppEnv) {
      data {
        id
        attributes {
          name
          summary
          apiProductID
          labels {
            type
            text
            TTL {
              startDate
              endDate
            }
          }
          icon {
            name
            borderStyle
            backgroundColor
          }
          readMoreButtonText
        }
      }
    }
  }
`;

export const apiProductsQuery = gql`
  query apiProductsQuery($locale: I18NLocaleCode, $filterByAppEnv: ApiProductFiltersInput!) {
    apiProducts(locale: $locale, filters: $filterByAppEnv) {
      data {
        id
        attributes {
          name
          apiProductID
          summary
          description
          icon {
            name
            borderStyle
            backgroundColor
          }
          labels {
            type
            text
            TTL {
              startDate
              endDate
            }
          }
          locale
        }
      }
    }
  }
`;

export const apiProductsRefsQuery = gql`
  query apiProductsRefsQuery($locale: I18NLocaleCode) {
    apiProducts(locale: $locale) {
      data {
        id
        attributes {
          apiProductID
        }
      }
    }
  }
`;

export const apiProductPageQuery = gql`
  query apiProductPageQuery($id: ID!) {
    apiProduct(id: $id) {
      data {
        attributes {
          name
          summary
          description
          spec
          apiProductID
        }
      }
    }
  }
`;

export const apiProductQuery = gql`
  query apiProductPageQuery($id: ID!, $locale: I18NLocaleCode) {
    apiProduct(locale: $locale, id: $id) {
      data {
        attributes {
          name
          summary
          description
          spec
          apiProductID
        }
      }
    }
  }
`;

export const navigationBarQuery = gql`
  query navigtaionBarQuery($locale: I18NLocaleCode, $filterByAppEnv: ComponentGlobalMenuOptionFiltersInput!) {
    navigationBar(locale: $locale) {
      data {
        attributes {
          menuOptions(filters: $filterByAppEnv) {
            buttonText
            route
            icon
          }
        }
      }
    }
  }
`;

export type HeroSectionAttributes = {
  apiProductsButtonText: string;
  header: string;
  text: string;
};

export type HeroSectionQueryResponse = {
  heroSection: {
    data: {
      attributes: HeroSectionAttributes;
    };
  };
};

export const heroSectionQuery = gql`
  query heroSectionQuery($locale: I18NLocaleCode) {
    heroSection(locale: $locale) {
      data {
        attributes {
          header
          text
          apiProductsButtonText
        }
      }
    }
  }
`;

export type FeedbackSectionAttributes = {
  feedbackButtonText: string;
  header: string;
  text: string;
};

export type FeedbackSectionQueryResponse = {
  feedbackSection: {
    data: {
      attributes: FeedbackSectionAttributes;
    };
  };
};

export const feedbackSectionQuery = gql`
  query feedbackSectionQuery($locale: I18NLocaleCode) {
    feedbackSection(locale: $locale) {
      data {
        attributes {
          header
          text
          feedbackButtonText
        }
      }
    }
  }
`;

export type ResponseApiProductsAllLocalesQuery = {
  data: {
    apiProducts: {
      data: {
        id: string;
        attributes: {
          name: string;
          summary: string;
          description: string;
          spec: JSON;
          locale: string;
        };
      }[];
    };
  };
};

export type ApiProductsAllLocalesQueryData = {
  id: string;
  attributes: {
    name: string;
    summary: string;
    description: string;
    spec: JSON;
    locale: string;
  };
};

export type ApiProductsAllLocalesQueryResponse = {
  apiProducts: {
    data: ApiProductsAllLocalesQueryData[];
  };
};

export type ApiProductsAllLocalesServerResponse = {
  apiProducts: {
    data: ApiProductsAllLocalesQueryData[];
  };
};

export const apiProductsAllLocalesQuery = gql`
  query apiProductsAllLocalesQuery($locale: I18NLocaleCode, $filters: ApiProductFiltersInput) {
    apiProducts(locale: $locale, filters: $filters) {
      data {
        id
        attributes {
          name
          summary
          description
          spec
        }
      }
    }
  }
`;

export type apiProductsPageContentQueryResponse = {
  apiProductsPage: {
    data: {
      attributes: {
        header: string;
        text: string;
      };
    };
  };
};

export const apiProductsPageContentQuery = gql`
  query apiProductsPageContentQuery($locale: I18NLocaleCode) {
    apiProductsPage(locale: $locale) {
      data {
        attributes {
          header
          text
        }
      }
    }
  }
`;

export type LocalizationsQueryResponse = {
  localization: {
    data: {
      attributes: {
        locale: {
          language: string;
          code: string;
          show: boolean;
        }[];
      };
    };
  };
};

export const localizationsQuery = gql`
  query localizationsQuery {
    localization {
      data {
        attributes {
          locale {
            language
            code
            show
          }
        }
      }
    }
  }
`;

export type LogoQueryResponse = {
  navigationBar: {
    data: {
      attributes: {
        logo: {
          data: {
            attributes: {
              url: string;
              alternativeText: string;
            };
          };
        };
      };
    };
  };
};

export const logoQuery = gql`
  query logoQuery($locale: I18NLocaleCode) {
    navigationBar(locale: $locale) {
      data {
        attributes {
          logo {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`;

export type FooterQueryResponse = {
  footer: {
    data: {
      attributes: {
        company: string;
        privacyPolicy: string;
      };
    };
  };
};

export const footerQuery = gql`
  query footerQuery($locale: I18NLocaleCode) {
    footer(locale: $locale) {
      data {
        attributes {
          company
          privacyPolicy
        }
      }
    }
  }
`;

export type LegacyApiAttributes = {
  name: string;
  summary: string;
  description: string;
  labels: LabelType[];
  icon?: IconTypes | undefined;
  spec: JSON;
  readMoreButtonText: string;
  tabs: {
    type: string;
  }[];
};

export type LegacyApiProduct = {
  id: number;
  attributes: LegacyApiAttributes;
};

export type LegacyApiProductCard = {
  id: number;
  attributes: Omit<LegacyApiAttributes, 'description' | 'spec' | ''>;
};

export type LegacyApiProductsQueryResponse = {
  legacyApiProducts: {
    data: LegacyApiProduct[];
  };
};

export const legacyApiProductsQuery = gql`
  query legacyApiProductsQuery($locale: I18NLocaleCode) {
    legacyApiProducts(locale: $locale) {
      data {
        id
        attributes {
          name
          icon {
            name
            borderStyle
            backgroundColor
          }
          labels {
            type
            text
            TTL {
              startDate
              endDate
            }
          }
          summary
          locale
          readMoreButtonText
          tabs {
            type
          }
        }
      }
    }
  }
`;

export type LegacyApiProductQueryResponse = {
  legacyApiProduct: {
    data: {
      id: string;
      attributes: {
        name: string;
        icon: {
          name: string;
          borderStyle?: string;
          backgroundColor?: string;
        };
        labels: {
          type: string;
          text: string;
          TTL?: {
            startDate: string;
            endDate: string;
          };
        };
        summary: string;
        locale: string;
        readMoreButtonText: string;
        tabs: {
          type: string;
          sections: {
            header: string;
            text: string;
          }[];
        }[];
      };
    };
  };
};

export const legacyApiProductQuery = gql`
  query legacyApiProductQuery($id: ID) {
    legacyApiProduct(id: $id) {
      data {
        id
        attributes {
          name
          icon {
            name
            borderStyle
            backgroundColor
          }
          labels {
            type
            text
            TTL {
              startDate
              endDate
            }
          }
          summary
          locale
          readMoreButtonText
          tabs {
            type
            sections {
              header
              text
            }
          }
        }
      }
    }
  }
`;

export interface I_startPageApiProducts {
  startPageApiCollection: {
    data: {
      attributes: {
        products: {
          legacy: boolean;
          api_product: {
            data: ApiProduct;
          };
          legacy_api_product: {
            data: LegacyApiProduct;
          };
        }[];
      };
    };
  };
}

export const startPageApiProductsQuery = gql`
  query startPageApiProductsQuery($locale: I18NLocaleCode) {
    startPageApiCollection(locale: $locale) {
      data {
        attributes {
          products {
            legacy
            api_product {
              data {
                id
                attributes {
                  name
                  summary
                  labels {
                    type
                    text
                    TTL {
                      startDate
                      endDate
                    }
                  }
                  icon {
                    name
                    borderStyle
                    backgroundColor
                  }
                  readMoreButtonText
                }
              }
            }
            legacy_api_product {
              data {
                id
                attributes {
                  name
                  summary
                  labels {
                    type
                    text
                    TTL {
                      startDate
                      endDate
                    }
                  }
                  icon {
                    name
                    borderStyle
                    backgroundColor
                  }
                  readMoreButtonText
                }
              }
            }
          }
        }
      }
    }
  }
`;
